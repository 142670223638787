<template>
  <div>
    <h1>Ajouter une formation continue</h1>
    <el-row>
      <el-col :span="12">
        <form-fcontinue
          :ismeddetail="ismeddetail"
          :isedit="false"
          ref="formFcont"
        />
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button type="primary" :disabled="isSaving" @click="onSubmit"
        >Enregistrer</el-button
      >
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormFcontinue from "@/components/rh/ui/form/FormFcontinue";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-fcontinue": FormFcontinue },

  data() {
    return {
      isSaving: false,
    };
  },
  props: {
    ismeddetail: Boolean,
    routes: Object,
  },
  created() {
    // récupère l'id du médecin si dans détail médecin
    const idMed = this.$route.params.idmed;
    this.$store.commit("fc/resetFcontinue", idMed);
  },
  computed: {
    fcontinueCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fc.fcontinueCreateStatus;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.formFcont.$refs.formFcontinue.validate((valid) => {
        if (valid) {
          let fcdata = this.$store.state.fc.fcontinue;

          // enlever les espaces avant et après dans les string
          trimStringInObj(fcdata);

          // vérifie si le titre existe déjà pour le médecin
          const fcInBD = this.$store.getters["fc/FcontinueInBD"];
          const fcDate =
            String(fcdata.annee) + (fcdata.mois ? "-" + fcdata.mois : "");
          const doublon =
            fcInBD.filter(
              (m) =>
                m[1] == fcdata.id_med &&
                m[2] == fcdata.id_fcont_titre &&
                m[3] == fcDate
            ).length > 0;
          if (doublon) {
            this.$alert(
              "Ce titre avec cette date a déjà été enregistré pour ce médecin",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur lance la création du nouvel enregistrement
            this.$store.dispatch("fc/createFcontinue", fcdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // retourne à la liste des formations continues
      this.$router.push(this.routes.tolist);
    },
  },
  watch: {
    fcontinueCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.fcontinueCreateStatus == 2) {
        // message succès
        this.$message({
          message: "La formation a été ajouté-e à la base de donnée",
          type: "success",
        });

        // va au formulaire d'édition du contrat
        this.$router.push(this.routes.toedit);
      } else if (this.fcontinueCreateStatus == 3) {
        this.isSaving = false;
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      } else if (this.fcontinueCreateStatus == 1) {
        this.isSaving = true;
      } else if (this.fcontinueCreateStatus == 0) {
        this.isSaving = false;
      }
    },
  },
};
</script>
