<template>
  <el-main class="rh-form">
    <form-fcontinuecreate :routes="fcontRoutes" />
  </el-main>
</template>
<script>
import FormFcontinuecreate from "@/components/rh/formulaires/FormFcontinuecreate";

export default {
  components: { "form-fcontinuecreate": FormFcontinuecreate },
  computed: {
    fcontRoutes() {
      return {
        toedit: {
          name: "fcontinueedit",
          params: { idfc: this.$store.state.fc.fcontinueLastCreatedId },
        },
        tolist: { name: "fcontinues" },
      };
    },
  },
};
</script>
